#loaderDiv {
	width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: #fff;
    overflow: hidden;
}

img {
    margin: auto;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#adminPanelButton
{
    position: absolute;
    background-color: cornflowerblue;
    border: 1px solid rgb(8, 34, 90);
    color:#fff;
    top: calc(50% - 25px);
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10px 0 0 10px;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    cursor: pointer;
    padding-right: 15px;
    transition: right .5s;
}

#adminPanelButton:hover {
    right: -1px;
}

#adminPanelButton div {
    margin-left: 5px;
}

.ant-upload-text-icon {
    visibility: hidden;
}