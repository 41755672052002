.viewResetPasswordAsk {
  height: 100%; }
  .viewResetPasswordAsk #backViewResetPass {
    height: 100%; }
    .viewResetPasswordAsk #backViewResetPass #head {
      height: 80px;
      line-height: 65px;
      padding-left: 1%; }
    .viewResetPasswordAsk #backViewResetPass #body {
      background-color: #fff;
      height: calc(100% - 80px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center; }
      .viewResetPasswordAsk #backViewResetPass #body #title {
        flex-grow: 2;
        position: relative;
        top: 20%; }
      .viewResetPasswordAsk #backViewResetPass #body #rest {
        flex-grow: 4;
        width: 50%;
        display: flex;
        margin: auto;
        flex-direction: column;
        justify-content: start; }
        .viewResetPasswordAsk #backViewResetPass #body #rest #description {
          margin-bottom: 20px; }
        .viewResetPasswordAsk #backViewResetPass #body #rest .inputEmail {
          margin-bottom: 20px;
          width: 80%;
          margin-left: auto;
          margin-right: auto; }
        .viewResetPasswordAsk #backViewResetPass #body #rest .btn {
          width: 20%;
          margin-left: auto;
          margin-right: auto; }
