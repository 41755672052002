.viewResetPassword {
  height: 100%; }
  .viewResetPassword #backViewResetPass {
    height: 100%; }
    .viewResetPassword #backViewResetPass #head {
      height: 80px;
      line-height: 65px;
      padding-left: 1%; }
    .viewResetPassword #backViewResetPass #body {
      background-color: #fff;
      height: calc(100% - 80px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center; }
      .viewResetPassword #backViewResetPass #body #title {
        flex-grow: 2;
        position: relative;
        top: 20%; }
      .viewResetPassword #backViewResetPass #body #rest {
        flex-grow: 4;
        width: 50%;
        display: flex;
        margin: auto;
        flex-direction: column;
        justify-content: start; }
        .viewResetPassword #backViewResetPass #body #rest #inputs {
          display: flex;
          flex-direction: column;
          height: 30%;
          justify-content: space-evenly;
          width: 50%;
          margin-left: auto;
          margin-right: auto; }
          .viewResetPassword #backViewResetPass #body #rest #inputs .btn {
            width: 40%;
            margin-left: auto;
            margin-right: auto; }
