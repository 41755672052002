.list {
  height: 95%; }

.load-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #EEF3FF;
  font-size: 14px;
  color: #2B3E50;
  text-align: center; }

.load-page.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.load-page > * {
  margin-left: auto;
  margin-right: auto; }

.load-page > .icon-fb {
  position: relative;
  width: 80px;
  height: 127.672px;
  display: inline-block;
  margin-bottom: 10px; }

.load-page > .icon-fb > * {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #2B3E50;
  animation-name: none, fbAnim3;
  animation-duration: 1.5s, 1.5s;
  animation-iteration-count: infinite, infinite;
  animation-direction: alternate-reverse, alternate-reverse; }

@keyframes fbAnim {
  0% {
    transform: scale(0.8); }
  25% {
    transform: scale(0.95); }
  50% {
    transform: scale(1); }
  75% {
    transform: scale(0.85); } }

@keyframes fbAnim3 {
  0% {
    background-color: transparent; }
  100% {
    background-color: #2B3E50; } }

@keyframes fbAnim2 {
  0% {
    transform: scale(1) rotate(45deg); }
  50% {
    transform: scale(1) rotate(135deg); }
  100% {
    transform: scale(1) rotate(135deg); } }

.load-page > .icon-fb > .ifb-1 {
  left: 12.12%;
  width: 28.28%;
  top: 63.28%;
  height: 36.08%;
  animation-delay: 0ms, 0ms; }

.load-page > .icon-fb > .ifb-2 {
  left: 12.12%;
  width: 57.57%;
  top: 44.93%;
  height: 17.72%;
  animation-delay: 200ms, 200ms; }

.load-page > .icon-fb > .ifb-3 {
  left: 12.12%;
  width: 28.28%;
  top: 26.58%;
  height: 17.72%;
  animation-delay: 400ms, 400ms; }

.load-page > .icon-fb > .ifb-4 {
  left: 42.42%;
  width: 57.57%;
  top: 8.22%;
  height: 17.72%;
  animation-delay: 600ms, 600ms; }

.load-page > .icon-fb > .ifb-5 {
  left: 6.37%;
  top: 5%;
  width: 28.28%;
  height: 17.72%;
  background-color: #2B3E50;
  animation-name: fbAnim2;
  animation-duration: 1s;
  animation-direction: normal; }

.load-page > .message {
  position: relative;
  font-size: 1.5em; }

@keyframes messageAnim {
  0% {
    content: ""; }
  33% {
    content: "."; }
  66% {
    content: ".."; }
  100% {
    content: "..."; } }

.load-page > .message:not(:empty)::after {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0;
  animation-name: messageAnim;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate; }
