.page-error {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%; }
  .page-error .error-content {
    padding-top: 10%;
    color: #ececec; }
    .page-error .error-content > details {
      cursor: pointer; }
    .page-error .error-content > a {
      color: inherit;
      text-decoration: none; }
