@import url(~antd/dist/antd.css);
* {
  box-sizing: border-box; }

::selection {
  color: white;
  background-color: #ececec; }

html, body, #root {
  height: 100%; }

body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 1em;
  font-family: "Quicksand", Calibri, Open Sans, Arial, sans-serif;
  color: #000;
  background-color: #00415b;
  overflow: auto; }

a:not(.link) {
  color: inherit;
  text-decoration: none; }

.messageToast {
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px; }
