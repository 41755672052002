.page-login {
  background-color: #00415b;
  color: #ececec;
  text-align: center;
  vertical-align: middle;
  height: 100% !important; }
  .page-login #backLogin {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    /* left: 25%; */
    /* position: relative; */
    margin: auto;
    top: 0;
    height: 100%; }
  .page-login #head {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20%; }
  .page-login #body {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
    .page-login #body .connectez {
      font-weight: 700;
      font-size: 1.1em; }
    .page-login #body #line {
      width: 100%;
      height: 1px;
      background-color: #fff; }
    .page-login #body #login {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 50%; }
      .page-login #body #login .ant-input-affix-wrapper:hover {
        border: 3px solid #e20513;
        border-left: none;
        border-right: none; }
      .page-login #body #login .ant-input-affix-wrapper-focused {
        border: 3px solid #e20513;
        border-left: none;
        border-right: none; }
        .page-login #body #login .ant-input-affix-wrapper-focused::placeholder {
          color: #e20513; }
      .page-login #body #login .inputLogin {
        border-radius: 15px; }
    .page-login #body #valide {
      display: flex;
      justify-content: space-between; }
      .page-login #body #valide a {
        font-size: 0.8em;
        padding-top: 15px; }
      .page-login #body #valide .btnValide {
        height: 50px;
        width: 40%;
        background-color: #e20513;
        border: none;
        color: #fff;
        font-size: 1.1em; }
        .page-login #body #valide .btnValide:hover {
          background-color: #fff;
          color: #e20513; }
